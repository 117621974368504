<template>
  <div style="display: flex; flex-direction: column">
    <div class="card card-body">
      <div class="row mb-3">
        <div class="col">
          <select v-model="filterStatus" class="form-control">
            <option :value="undefined">{{ $t('tickets.all') }}</option>
            <option :value="1">{{ $t('tickets.status_open') }}</option>
            <option :value="2">{{ $t('tickets.status_consideration') }}</option>
            <option :value="3">{{ $t('tickets.status_in_work') }}</option>
            <option :value="5">{{ $t('tickets.status_solved') }}</option>
            <option :value="4">{{ $t('tickets.status_closed') }}</option>
          </select>
        </div>
        <div class="col">
          <div class="app-search p-0">
            <div class="position-relative">
              <input v-model="filter" type="text" class="form-control" :placeholder="$t('gameservers.search')">
              <span class="uil-search"></span>
            </div>
          </div>
        </div>
        <div class="col d-grid">
          <router-link to="ticket/new" class="btn btn-outline-primary text-primary">
            <i class="mdi mdi-plus"></i> {{ $t('tickets.new_ticket') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-responsive">
            <b-table
                hover
                @row-clicked="onTicketClicked"
                table-class="table table-centered datatable"
                thead-tr-class="bg-transparent"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                @filtered="onFiltered"
                :fields="fields"
                :items="filteredTicketList">
              <template v-slot:cell(status)="filteredTicketList">
                <div :class="['badge', getTicketStatusClass(filteredTicketList.item.status)]">
                  {{ $t(getTicketStatusText(filteredTicketList.item.status)) }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="d-lg-flex mb-4">-->
    <!--      <b-modal-->
    <!--          v-model="showModal"-->
    <!--          :title="$t('gameservers.confirmation')"-->
    <!--          :ok-title="$t('gameservers.confirm')"-->
    <!--          :cancel-title="$t('gameservers.cancel')"-->
    <!--          @ok="closeTicket(ticket_data.ticket_id)"-->
    <!--      >{{ $t('gameservers.confirm_question') }}-->
    <!--      </b-modal>-->
    <!--      <div class="chat-leftsidebar card">-->
    <!--        <div class="p-3">-->
    <!--          <h5 class="font-size-16 mb-3">-->
    <!--            <i class="me-1"></i> {{ $t('tickets.status') }}-->
    <!--          </h5>-->

    <!--          <select v-model="filterStatus" class="form-control">-->
    <!--            <option :value="undefined">{{ $t('tickets.all') }}</option>-->
    <!--            <option :value="1">{{ $t('tickets.status_open') }}</option>-->
    <!--            <option :value="2">{{ $t('tickets.status_consideration') }}</option>-->
    <!--            <option :value="3">{{ $t('tickets.status_in_work') }}</option>-->
    <!--            <option :value="4">{{ $t('tickets.status_closed') }}</option>-->
    <!--          </select>-->
    <!--        </div>-->

    <!--        <div class="p-3 border-top">-->
    <!--          <h5 class="font-size-16 mb-3">-->
    <!--            <i class="me-1"></i> {{ $t('tickets.category') }}-->
    <!--          </h5>-->

    <!--          <select v-model="filterCategory" class="form-control">-->
    <!--            <option :value="undefined">{{ $t('tickets.all') }}</option>-->
    <!--            <option-->
    <!--                v-for="category in categoryList"-->
    <!--                :key="category.cid"-->
    <!--                :value="category.cid"-->
    <!--            >{{ category.name }}-->
    <!--            </option>-->
    <!--          </select>-->
    <!--        </div>-->

    <!--        <div class="p-3 border-top">-->
    <!--          <div class="float-end">-->
    <!--            <router-link-->
    <!--                to="ticket/new"-->
    <!--                class="text-primary"-->
    <!--            >-->
    <!--              <i class="mdi mdi-plus"></i> {{ $t('tickets.new_ticket') }}-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--          <h5 class="font-size-16 mb-3">-->
    <!--            <i class="me-1"></i> {{ $t('tickets.ticket_list') }}-->
    <!--          </h5>-->
    <!--        </div>-->

    <!--        <div class="p-3">-->
    <!--          <simplebar style="max-height: 470px" id="chat-list" ref="currenttickets">-->
    <!--            <ul class="list-unstyled chat-list">-->
    <!--              <li-->
    <!--                  v-for="(ticket, index) of filteredTicketList"-->
    <!--                  :key="index"-->
    <!--                  @click="$store.commit('tickets/SET', ticket.id); getTicketData(ticket.id)"-->
    <!--                  :class="{ active: $store.state.tickets.ticket_id == ticket.id, 'ticket-active': $store.state.tickets.ticket_id == ticket.id }"-->
    <!--              >-->
    <!--                <a href="#">-->
    <!--                  <div class="media">-->
    <!--                    <div-->
    <!--                        class="user-img align-self-center me-3"-->
    <!--                    >-->
    <!--                      <small-->
    <!--                          :class="['badge', getTicketStatusClass(ticket.status)]"-->
    <!--                      >{{ $t(getTicketStatusText(ticket.status)) }}</small>-->
    <!--                    </div>-->
    <!--                    <div class="media-body overflow-hidden">-->
    <!--                      <h5 class="text-truncate font-size-14 mb-1">-->
    <!--                        {{ ticket.title }}-->
    <!--                      </h5>-->
    <!--                      <p class="text-truncate mb-0">-->
    <!--                        <template v-if="ticket.last_message.username">{{ ticket.last_message.username }}</template>-->
    <!--                        <template v-else-if="$store.state.session.uid == ticket.last_message.uid">-->
    <!--                          {{ $store.state.session.email }}-->
    <!--                        </template>-->
    <!--                        <template v-else>{{ $t('tickets.admin') }}</template>-->
    <!--                      </p>-->
    <!--                    </div>-->
    <!--                    <div class="font-size-11">{{ ticket.date }}</div>-->
    <!--                  </div>-->
    <!--                </a>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </simplebar>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      &lt;!&ndash; end chat-leftsidebar &ndash;&gt;-->

    <!--      <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1" v-if="ticket_data">-->
    <!--        <div class="card">-->
    <!--          <div class="p-3 px-lg-4 border-bottom">-->
    <!--            <div class="row">-->
    <!--              <div class="col-md-4 col-6">-->
    <!--                <h5 class="font-size-16 mb-1 text-truncate">-->
    <!--                  id {{ ticket_data.ticket_id }} - {{ ticket_data.ticket_title }}-->
    <!--                </h5>-->
    <!--              </div>-->
    <!--              <div class="col-md-8 col-6" v-show="ticket_data.ticket_status != 4">-->
    <!--                <ul class="list-inline user-chat-nav text-end mb-0">-->
    <!--                  <button class="btn btn-sm btn-danger" @click="showModal = true">{{ $t('tickets.close_ticket') }}-->
    <!--                  </button>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--              <div class="row">-->
    <!--                <div class="col">-->
    <!--                  <strong>{{ $t('tickets.category') }}:</strong>-->
    <!--                  {{ ticket_data.ticket_category_name }}-->
    <!--                </div>-->
    <!--                <div class="col">-->
    <!--                  <strong>{{ $t('tickets.status') }}:</strong>-->
    <!--                  {{ $t(getTicketStatusText(parseInt(ticket_data.ticket_status))) }}-->
    <!--                </div>-->
    <!--                <div class="col"><strong>{{ $t('tickets.game') }}:</strong> {{ ticket_data.game_name }}</div>-->
    <!--              </div>-->
    <!--              <div class="row">-->
    <!--                <div class="col"><strong>{{ $t('tickets.gameserver') }}:</strong>-->
    <!--                  <template v-if="ticket_data.game_server_id > 0">[{{ ticket_data.game_server_id }}]-->
    <!--                    {{ ticket_data.game_server_ip }}:{{ ticket_data.game_server_port }}-->
    <!--                  </template>-->
    <!--                </div>-->
    <!--                <div class="col"><strong>{{ $t('tickets.addon') }}:</strong> {{ ticket_data.addon_name }}</div>-->
    <!--                <div class="col"><strong>{{ $t('tickets.machine') }}:</strong>-->
    <!--                  <template v-if="ticket_data.machine_id > 0">[{{ ticket_data.machine_id }}]-->
    <!--                    {{ ticket_data.machine_name }} - {{ ticket_data.machine_ip }}-->
    <!--                  </template>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="px-lg-2 chat-users">-->
    <!--            <div class="p-3">-->
    <!--              <simplebar style="max-height: 450px" class="chat-conversation" id="containerElement" ref="current">-->
    <!--                <ul class="list-unstyled mb-0">-->
    <!--                  <li-->
    <!--                      v-for="message in message_list"-->
    <!--                      :key="message.id"-->
    <!--                      :class="{ right: $store.state.session.uid == message.uid }"-->
    <!--                  >-->
    <!--                    <div class="conversation-list">-->
    <!--                      <div class="ctext-wrap">-->
    <!--                        <div class="ctext-wrap-content">-->
    <!--                          <h5 class="font-size-14 conversation-name">-->
    <!--                            <a href="#" class="text-dark">-->
    <!--                              <template v-if="message.username">{{ message.username }}</template>-->
    <!--                              <template v-else-if="$store.state.session.uid == message.uid">-->
    <!--                                {{ $store.state.session.email }}-->
    <!--                              </template>-->
    <!--                              <template v-else>{{ $t('tickets.admin') }}</template>-->
    <!--                            </a>-->
    <!--                            <span-->
    <!--                                class="d-inline-block font-size-12 text-muted ms-2"-->
    <!--                            >{{ message.date }}</span-->
    <!--                            >-->
    <!--                          </h5>-->
    <!--                          <p style="white-space: pre-wrap; text-align: left;" v-html="wrapEmoji(message.message)"></p>-->
    <!--                          <p v-if="message.attachment_mime_type.indexOf('image') === 0">-->
    <!--                            <img :src="message.attachment_url"-->
    <!--                                 style="max-height: 300px; min-width: 1px; height: auto; width: auto;cursor: pointer"-->
    <!--                                 @click="message.attachment_visible = true">-->
    <!--                            <vue-easy-lightbox-->
    <!--                                :visible="message.attachment_visible"-->
    <!--                                :imgs="message.attachment_url"-->
    <!--                                @hide="message.attachment_visible = false"-->
    <!--                            ></vue-easy-lightbox>-->
    <!--                          </p>-->
    <!--                          <div class="media pt-2" v-else-if="message.attachment_url"-->
    <!--                               style="border-top: 1px solid rgba(0,0,0,.1);cursor: pointer"-->
    <!--                               @click="downloadAttachment(message.attachment_url)">-->
    <!--                            <div-->
    <!--                                class="user-img align-self-center me-3"-->
    <!--                            >-->
    <!--                              <i class="fa fa-paperclip" aria-hidden="true"></i>-->
    <!--                            </div>-->
    <!--                            <div class="media-body overflow-hidden">-->
    <!--                              <h5 class="text-truncate font-size-14 mb-1 text-primary">-->
    <!--                                {{ message.attachment_filename }}-->
    <!--                              </h5>-->
    <!--                              <p class="text-truncate mb-0">-->
    <!--                                {{ formatBytes(message.attachment_size) }} • {{ $t('tickets.download') }}-->
    <!--                              </p>-->
    <!--                            </div>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </li>-->
    <!--                  <li-->
    <!--                      v-if="ticket_data.ticket_status == 4"-->
    <!--                  >-->
    <!--                    <div class="conversation-list">-->
    <!--                      <div class="ctext-wrap">-->
    <!--                        <div class="ctext-wrap-content">-->
    <!--                          <p class="mb-0" style="white-space: pre-wrap">{{ $t('tickets.closed_ticket_info') }}</p>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </simplebar>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="row p-3 chat-input-section" v-show="ticket_data.ticket_status != 4">-->
    <!--            <div class="col">-->
    <!--              <div class="position-relative">-->
    <!--                <Picker v-show="showEmojiPicker" :data="emojiIndex" set="apple" @select="addEmoji" :defaultSkin=1-->
    <!--                        :skin=1 :showPreview=false :showSearch=false :showSkinTones=false :infiniteScroll=false-->
    <!--                        :emojiSize=22/>-->
    <!--                <span-->
    <!--                    class="emoji-trigger"-->
    <!--                    :class="{ 'triggered': showEmojiPicker }"-->
    <!--                    @mousedown.prevent="toggleEmojiPicker"-->
    <!--                >-->
    <!--                <svg-->
    <!--                    style="width:20px;height:20px"-->
    <!--                    viewBox="0 0 24 24"-->
    <!--                >-->
    <!--                  <path fill="#888888"-->
    <!--                        d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z"/>-->
    <!--                </svg>-->
    <!--              </span>-->
    <!--                <textarea-->
    <!--                    class="form-control chat-input rounded"-->
    <!--                    id="input"-->
    <!--                    :placeholder="$t('tickets.message')"-->
    <!--                    v-model="message"-->
    <!--                    rows="4"-->
    <!--                    ref="textarea"-->
    <!--                ></textarea>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="col-auto">-->
    <!--              <div class="d-grid gap-2">-->
    <!--                <button-->
    <!--                    @click="sendTicketMessage(ticket_data.ticket_id)"-->
    <!--                    class="btn btn-primary chat-send w-md waves-effect waves-light"-->
    <!--                >-->
    <!--                  <span class="d-none d-sm-inline-block me-2">{{ $t('tickets.submit') }}</span>-->
    <!--                  <i class="mdi mdi-send float-end"></i>-->
    <!--                </button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import axios from "@/modules/Axios";

/**
 * Компонент - список тикетов
 */
export default {
  name: "TicketList",
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filter: '',
      // Список тикетов
      ticketList: [],
      // Фильтр тикетов по статусу
      filterStatus: undefined,
      // object информация о тикете
      ticket_data: '',
    };
  },
  computed: {
    fields() {
      return [{
        key: 'title',
        sortable: true,
        label: this.$t('tickets.subject')
      }, {
        key: 'id',
        sortable: true,
        label: this.$t('tickets.number')
      }, {
        key: 'status',
        sortable: true,
        label: this.$t('tickets.status')
      }, {
        key: 'last_message.date',
        sortable: true,
        label: this.$t('tickets.changed')
      },]
    },
    totalRows() {
      return this.filteredTicketList.length
    },
    /**
     * Вычисляемое свойство - список тикетов по выбранным фильтрам
     */
    filteredTicketList() {
      // Список который будет фильтровать
      let filteredList = this.ticketList;

      // Фильтруем по выбранному статусу
      if (typeof this.filterStatus === "number")
        filteredList = filteredList.filter(
            ticket => ticket.status === this.filterStatus
        );

      return filteredList;
    }
  },
  methods: {
    onTicketClicked(ticket) {
      this.$store.commit('tickets/SET', ticket.id);
      this.$router.push(`./ticket/${ticket.id}`)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /**
     * Метод запроса списка тикетов
     */
    async getTicketList() {
      // Очищаем список тикетов
      this.ticketList = [];

      // Отображаем иконку загрузки
      this.showLoader = true;

      // Отправляем запрос на сервер
      try {
        const response = await axios.post("Ticket.php");
        // Скрываем иконку загрузки
        this.showLoader = false;

        // Если список тикетов пуст - отображаем пустой список тикетов
        if (typeof response.data === "undefined") return;

        // Конвертируем тикеты и заполняем список тикетов
        response.data.forEach(ticket => {
          this.ticketList.push({
            id: parseInt(ticket.tid),
            title: ticket.title,
            date: ticket.date,
            upid: parseInt(ticket.upid),
            status: parseInt(ticket.status),
            last_message: {
              date: ticket.last_message.date,
              value: ticket.last_message.value,
              uid: ticket.last_message.uid
            }
          });
        });
      } catch (error) {
        console.error(error.message);
      }
    },
    getTicketStatusClass(status) {
      switch (status) {
        case 1:
          return "bg-success";
        case 2:
          return "bg-info";
        case 3:
          return "bg-warning";
        case 4:
          return "bg-danger";
        case 5:
          return "bg-primary";
      }
    },
    getTicketStatusText(status) {
      switch (status) {
        case 1:
          return "tickets.status_ticket_open";
        case 2:
          return "tickets.status_ticket_consideration";
        case 3:
          return "tickets.status_ticket_in_work";
        case 4:
          return "tickets.status_ticket_closed";
        case 5:
          return "tickets.status_ticket_solved";
      }
    },
    getTicketDate(date) {
      const diff = new Date() - date;

      let message = undefined;
      if (diff / 1000 < 1.0) message = "Меньше секунды назад";
      if (typeof diff !== "undefined" && diff / 1000 / 60 < 1.0)
        message = "Меньше минуты назад";
      if (typeof diff !== "undefined" && diff / 1000 / 60 / 60 < 1.0)
        message = "Меньше часа назад";
      if (typeof diff !== "undefined" && diff / 1000 / 60 / 60 / 24 < 1.0)
        message = "Меньше суток назад";
      else message = "Больше суток назад";

      return message;
    },
    downloadAttachment(url) {
      window.open(url, '_blank')
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 B';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  /**
   * Обработчик события "Компонент монтирован"
   */
  mounted() {
    // Вызываем метод получения списка тикетов
    this.getTicketList();
  }
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>
